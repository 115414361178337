import './App.css';
// import "leaflet/dist/leaflet.css";

import csvFile from './assets/cleaned_sites.csv'
import list_images from './assets/list_images.json'
import geojson_mapped_ids from './assets/aceh_adm2_mapped.json'
import geojson_source from './assets/aceh_adm2.json'

import iconClose from './assets/icon-close.png'
import iconArea from './assets/icon-filter-area.png'
import iconHint from './assets/icon-hint.png'

import logoAJAR from './assets/logo-AJAR.png'
import logoLBHBANDAACEH from './assets/logo-LBH-Banda-Aceh.png'
import logoPASKAACEH from './assets/logo-PASKA-Aceh.jpg'
import logoKONTRASACEH from './assets/logo-KontraS-Aceh.png'

// import iconBrown from './assets/leaf-icon-brown.png'
// import iconActive from './assets/leaf-icon-active.png'
// import iconHighlight from './assets/leaf-icon-highlight.png'

import { useEffect, useState } from 'react';

import { get, keyBy, map, uniq, some, mapValues, filter, orderBy  } from 'lodash'

import { X } from 'react-feather'
import Slider from "react-slick";
import Papa from 'papaparse'
import L from "leaflet";
import { MapContainer, TileLayer, CircleMarker, Marker, GeoJSON, ZoomControl } from 'react-leaflet';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const tileLayer = {
  attribution: 'Map data &copy;  Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
  url: 'https://api.mapbox.com/styles/v1/asiajusticeandrights/clo6vcnd200s301qmdo15gt99/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYXNpYWp1c3RpY2VhbmRyaWdodHMiLCJhIjoiY2xvNnJwanJzMG10NzJqbnV2M3FxcXA2eCJ9.lp7sh5dmJKf09InTxJGJSQ'
}

const center = [4, 97];
const defaultZoom = 8;
const maxZoom = 12;

function App() {
  const [lmap, setMap] = useState(null)
  const [lgeo, setGeojson] = useState(null)

  const [raw_locations, setRawLocations] = useState([])
  const [filtered_locations, setFilteredLocations] = useState({})
  
  const [dropdownStatus, setDropdownStatus] = useState({
    area: false,
    befote: false,
    after: false,
    condition: false,
    hint: true,
  })
  const [areaDropdownOpts, setAreaDropdownOpts] = useState(null)
  const [hintPage, setHintPage] = useState(1)

  const [activePin, setActivePin] = useState(null)
  const [activeLocation, setActiveLocation] = useState(null)

  const closeAllDropdown = () => {
    if (some(dropdownStatus)) setDropdownStatus(mapValues(dropdownStatus, (o) => (false)))
  }

  const setDropdownStatusByKey = (key, value) => {
    setDropdownStatus({ ...dropdownStatus, ...{ [key]: value } })
  }

  const stopPropagation = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  }

  const onAreaSelected = (selected) => {
    let filtered_geojson = {...geojson_source, ...{ features: geojson_source.features.filter(o => o.properties.GID_2 === selected.GID_2)  }}
    setActiveLocation({...selected, ...{ geojson: filtered_geojson }})

    // setDropdownStatusByKey('area', false)
  }

  const panToGeo = () => {
    let bounds = lgeo.getBounds()
    let center = bounds.getCenter()
    lmap.fitBounds(bounds)
    lmap.panTo(center)
  }

  useEffect(() => {
    Papa.parse(csvFile, {
      download: true,
      header: true,
      complete: function (input) {
        const null_removed = keyBy(input.data.filter(o => (o.Latitude && o.Longitude)), 'No')
        setRawLocations(null_removed)
        setFilteredLocations(null_removed)

        setAreaDropdownOpts(geojson_mapped_ids.filter(o => (uniq(map(null_removed, 'ADM2')).indexOf(o.NAME_2) > -1)))
      }
    });
  }, [])

  useEffect(() => {
    if (lmap) {
      setInterval(() => {
        lmap.invalidateSize();
      }, 100);
    }
  },[lmap])

  useEffect(() => {
    if (lgeo) {
      setActivePin(null)
      panToGeo()
    }
  },[lgeo])

  useEffect(() => {
    let filtered = raw_locations;

    if (activeLocation) {
      filtered = filter(filtered, o => (o.ADM2 === activeLocation.NAME_2))
    }
    
    setFilteredLocations(keyBy(filtered, 'No'))
  },[activeLocation])
  
  const rippleIcon = new L.divIcon({
    className: 'ripple',
    html: `<div class="ring-wrapper">
      <div class="ring-1"></div>
      <div class="ring-2"></div>
      <div class="ring-3"></div>
    </div>`,
    iconSize: [22, 22],
    iconAnchor: [-3, 25]
  })

  const ListOfMarkers = () => {
    return map(orderBy(filtered_locations, ['Emblematic Case', 'No'], ['asc', 'asc']), (val) => (
      <CircleMarker
        key={ val['No'] }
        center={{ lat: parseFloat(val.Latitude), lng: parseFloat(val.Longitude)  }}
        radius={(activePin === val['No']) ? 6 : (val['Emblematic Case'] === 'True') ? 5 : 4}
        // className={((activePin === val['No'] || val['Emblematic Case'] === 'True') ? 'above' : '')}
        color={ val['Emblematic Case'] === 'True' ? '#CC672E'  : '#533F36'}
        fillOpacity={activePin  === val['No'] ? .2 : 1}
        eventHandlers={{
          click(e) {
            setActivePin(val['No'])
            // console.log(filtered_locations[val['No']]);

            const location = e.target.getLatLng();
            lmap.flyTo([location.lat, location.lng], maxZoom);
          }
        }}
      >
        {/* <Popup>No: {No}; Latitude: {Latitude}; Longitude: {Longitude}</Popup> */}
      </CircleMarker>
    ));
  }
  
  const onClosePopup = () => {
    setActivePin(null)
    if (activeLocation) {
      panToGeo()
    } else {
      lmap.flyTo(center, defaultZoom)
    }
  }
  
  const onRemoveAreaSelection = () => {
    setActiveLocation(null)
    if (!activePin) {
      lmap.flyTo(center, defaultZoom)
    }

    setDropdownStatusByKey('area', false)
  }

  const PopupComponent = () => (
    <div className='box-details'>
      <img src={iconClose} alt="closePopup" onClick={onClosePopup} style={{ cursor: 'pointer' }} />
      <div className='box-content'>
        <h4>{ get(filtered_locations, [activePin, 'Name of the post ']) || '-' }</h4>
        <div style={{ margin: "20px 0px" }}>
          <div>Kabupaten: <b>{ get(filtered_locations, [activePin, 'ADM2']) || '-' }</b></div>
          <div>Kecamatan: <b>{ get(filtered_locations, [activePin, 'ADM3']) || '-' }</b></div>
          <div>Kemukiman: <b>{ get(filtered_locations, [activePin, 'ADM4']) || '-' }</b></div>
          <div>Gampong: <b>{ get(filtered_locations, [activePin, 'ADM5']) || '-' }</b></div>
        </div>
        <div style={{ margin: "20px 0px" }}>
          <div>Tahun Aktif: <b>{ get(filtered_locations, [activePin, 'Years active ']) || '-' }</b></div>
          <div>Kondisi: <b>{ get(filtered_locations, [activePin, 'Condition status']) || '-' }</b></div>
        </div>
        <p>{ get(filtered_locations, [activePin, 'Description of the site '], 'Tidak ada dekripsi untuk situs ini')}</p>
      </div>
    </div>
  )
  
  const ImageComponent = () => {
    let slider_settings = {
      dots: false,
      adaptiveHeight: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      lazyLoad: 'progresive'
    }
    
    let images = get(list_images, activePin, [])

    return (
      <div className='image-wrapper'>
        <div className='image-title'>Foto Kondisi</div>
        <div className='image-content'>
          { images.length ?
            <Slider {...slider_settings}>
              { images.map(o => (
                <div key={o}>
                  <img src={'/images/' + o} />
                </div>
              )) }
            </Slider>
            : <div className='nodata'>Foto belum tersedia</div>
          }
        </div>
      </div>
    )
  }

  const ButtonWrapper = () => (
    <div className='button-wrapper'>
      <div className='button-hint'>
        <img src={iconHint} onClick={() => { setHintPage(1); setDropdownStatusByKey('hint', true) }}/>
      </div>
      <div className='filter-area-wrapper' key='button-area'>
        <img src={iconArea} style={{ cursor: 'pointer' }} onClick={(e) => { stopPropagation(e); setDropdownStatusByKey('area', !dropdownStatus.area)  }}></img>
        {dropdownStatus.area && <div className='dropdown-pointer'></div> }
        {(dropdownStatus.area && !activeLocation) && <ul className="area-menu dropdown">
          <li className='title'>Select Regency</li>
          { areaDropdownOpts && areaDropdownOpts.map((o) => (
            <li key={o.GID_2} className='area-opts' onClick={(e) => {stopPropagation(e); onAreaSelected(o)}}>{o.NAME_2}</li>
          )) }
        </ul>}
        {(dropdownStatus.area && activeLocation) && <ul className="area-menu dropdown">
          <li className='area-selected'>
            <span>{ activeLocation.NAME_2 }</span>
            <X  onClick={(e) => { stopPropagation(e); onRemoveAreaSelection() }}/>
          </li>
        </ul>}
      </div>
    </div>
  )

  const HintArea = () => (
    <div className='hint-wrapper' onClick={(e) => stopPropagation(e)}>
      <div className='hint-overlay'></div>
      <div className='hint-content'>
      <div className='hint-title'>Peta Digital Situs Penyiksaan dan Perlakuan atau Penghukuman yang Kejam, Tidak Manusiawi, atau Merendahkan Martabat Semasa Konflik di Aceh Tahun 1976-2005</div>
        {(hintPage === 1) && <div className='hint-first'>
          <p>Peta digital ini untuk mendorong pengungkapan kebenaran berbagai kasus penyiksaan dan perlakuan atau penghukuman yang kejam, tidak manusiawi, atau merendahkan martabat di Aceh. Peta memuat kumpulan data dan informasi yang telah terverifikasi mengenai tempat-tempat penyiksaan yang dikerjakan oleh tim kerja dari LBH Banda Aceh, KontraS Aceh, PASKA Aceh dan AJAR. Pengumpulan berbagai cerita tentang situs penyiksaan yang penting dilakukan untuk mendukung upaya pemusatan data, advokasi korban, dan memorialisasi.</p>
          <p>Peta memuat informasi mengenai titik lokasi penyiksaan, deskripsi peristiwa, keterangan saksi atau korban, dan gambaran mengenai pelaku kasus penyiksaan di Aceh. Proses ini juga secara langsung mendukung upaya advokasi keadilan dan pemenuhan hak korban yang dilakukan oleh lembaga pemerintah maupun kelompok masyarakat sipil. Selain itu, dokumen ini juga diharapkan membantu kebijakan memorialisasi di Aceh sebagai bentuk reparasi dan usaha merawat ingatan.</p>
          <p>Sejak tahun 2020, tim kerja LBH Banda Aceh, KontraS Aceh, dan AJAR menginisiasi rencana pembuatan peta digital berbagai tempat penyiksaan di Aceh. Upaya pendokumentasian dan verifikasi ini dimaksudkan untuk mengembangkan data terpadu mengenai lokasi-lokasi kasus penyiksaan di Aceh yang belum pernah dilakukan secara resmi oleh pemerintah maupun kelompok masyarakat sipil. Kerja bersama ini diawali diskusi dengan akademisi, para ahli, dan perwakilan kelompok masyarakat sipil lainnya. Berbagai data mengenai kasus penyiksaan di Aceh yang pernah dirilis lembaga negara maupun NGO dipelajari sebagai bahan informasi awal. Pada 2022, tim kerja mulai turun ke berbagai wilayah di Aceh untuk memperoleh informasi yang belum pernah terdokumentasi. Khusus untuk wilayah di Kabupaten Pidie dan Pidie Jaya, tim kerja juga bekerja sama dengan PASKA Aceh, komunitas korban konflik yang bekerja di wilayah tersebut untuk mendokumentasikan dan memverifikasi data situs sejak tahun 2016.</p>
          <p>Proses dokumentasi dalam pengembangan peta digital dilakukan di hampir semua wilayah konflik di 12 Kabupaten dan Kota di Aceh. Wilayah-wilayah tersebut antara lain: Aceh Besar, Aceh Utara, Pidie, Pidie Jaya, Aceh Timur, Biereun, Banda Aceh, Lhokseumawe, Aceh Jaya, Aceh Barat, Bener Meriah, dan Aceh Tengah. Mayoritas peristiwa penyiksaan yang dicatat dalam submisi dan peta digital di daerah-daerah tersebut terjadi dalam kurun waktu kurang lebih 29 tahun sejak 1976 hingga 2005.</p>
          <div className='hint-button' onClick={() => setHintPage(2)}>Lanjut</div>
        </div>}
        {(hintPage === 2) && <div className='hint-second'>
          <div className='hint-contributors'>
            <span>Riset dan pendokumentasian bersama organisasi masyarakat sipil</span>
            <div className='hint-contributors-images'>
              <img id="ajar" src={logoAJAR} onClick={()=> window.open("https://asia-ajar.org", "_blank")} />
              <img id="lbhbandaaceh" src={logoLBHBANDAACEH} onClick={()=> window.open("https://lbhbandaaceh.org", "_blank")} />
              <img id="paskaaceh" src={logoPASKAACEH} onClick={()=> window.open("https://paska-aceh.or.id", "_blank")} />
              <img id="kontrasaceh" src={logoKONTRASACEH} onClick={()=> window.open("https://kontrasaceh.or.id", "_blank")} />
            </div>
          </div>
          <div className='hint-hint'>
            <span>Petunjuk penggunaan peta:</span>
            <div className='hint-hint-images'>
              <div style={{ gridColumn: '4 / span 5' }}>
                <div className='ceil'>
                  <div className='circle'></div>
                </div>
                <div className='floor'>Pilih titik situs untuk melihat secara lebih detail</div>
              </div>
              <div style={{ gridColumn: '12 / span 5' }}>
                <div className='ceil'>
                  <img src={iconArea} />
                </div>
                <div className='floor'>Tekan untuk menyaring situs di area tertentu</div>
              </div>
            </div>
          </div>
          <div className='hint-button-wrapper'>
            <div className='hint-button gray-color' onClick={() => setHintPage(1)}>Kembali</div>
            <div className='hint-button' onClick={() => setDropdownStatusByKey('hint', false)}>Buka Peta</div>
          </div>
        </div>}
      </div>
    </div>
  )
  
  return (
    <div className='map-wrapper' onClick={() => { closeAllDropdown() }}>
      <MapContainer
        ref={setMap}
        center={center}
        zoomControl={false}
        zoom={defaultZoom}
        maxZoom={maxZoom}
        minZoom={defaultZoom}
        scrollWheelZoom={false}
        maxBounds={[
          // south west
          [2.086104, 94.680486],
          // north east
          [6.051576, 99.683737]
        ]}
      >
        <ZoomControl position='bottomleft'/>
        <TileLayer {...tileLayer} />
        <ListOfMarkers />
        { activePin && <Marker
          position={{
            lat: parseFloat(get(filtered_locations, [activePin, 'Latitude'], 0)),
            lng: parseFloat(get(filtered_locations, [activePin, 'Longitude'], 0))
          }}
          icon={rippleIcon}
        ></Marker> }
        {activeLocation && <GeoJSON 
          color="#AF4900"
          ref={setGeojson}
          eventHandlers={{  }}
          key={activeLocation.GID_2}
          data={activeLocation.geojson}
        />}
      </MapContainer>
      { dropdownStatus.hint === false && <ButtonWrapper/> }
      { (activePin && dropdownStatus.hint === false) && <PopupComponent /> }
      { (activePin && dropdownStatus.hint === false) && <ImageComponent /> }
      { dropdownStatus.hint && <HintArea /> }
      { dropdownStatus.hint === false && <div className='title-wrapper'>PETA DIGITAL SITUS PENYIKSAAN</div>}
    </div>
  )
}

export default App;
